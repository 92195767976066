$(window).scroll(function() {
  var scroll = $(window).scrollTop();
  var pathname = window.location.pathname;

  if (pathname === "/") {
    if (scroll >= 250) {
      $(".homepage_logo").css("opacity", 1)
      $(".navigation").removeClass("clear_header").addClass("dark_header");
    } else {
      $(".homepage_logo").css("opacity", 0);
      $(".navigation").removeClass("dark_header").addClass("clear_header");
    }
  } else {
    if (scroll >= 5) {
      $(".navigation").removeClass("clear_header").addClass("dark_header");
    } else {
      $(".navigation").removeClass("dark_header").addClass("clear_header");
    }
  }
});
